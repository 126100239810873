import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

function createData(name, maximum_loan_terms, apr) {
  return { name, maximum_loan_terms, apr };
}

const rows = [
  createData('2024 - 2025', '24, 36, 48, 60 Months', '5.24%'),
  createData('2024 - 2025', '72 Months', '5.99%'),
  createData('2024 - 2025', '84 Months', '6.24%'),
  createData('2022 - 2023', '24, 36, 48, 60 Months', '5.24%'),
  createData('2018 - 2021', '24, 36, 48 Months', '5.24%'),
  createData('2015 - 2017', '24, 36, 48 Months', '7.49%'),
  createData('2007 - 2014', '24, 36, 48 Months', '8.49%'),
];

export default function AutoLoansTable() {
  return (
    <TableContainer>
      <Table stickyHeader={true} aria-label="table">
        <TableHead>
          <TableRow>
            <TableCell>Model Year(s)</TableCell>
            <TableCell align="right">Maximum Loan Term(s)</TableCell>
            <TableCell align="right">APR</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.name}
              sx={{
                '&:last-child td, &:last-child th': {
                  border: 0,
                },
              }}
            >
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="right">{row.maximum_loan_terms}</TableCell>
              <TableCell align="right">{row.apr}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
